.List-container {
    display: flex;
    justify-content: left;
    align-items: left;

    font-family: "roboto";
    height: 80px;
    width: 650px;
    background-color: white;
    border-radius: 20px;

    box-shadow: 5px 5px 4px 0px rgba(0, 0, 0, 0.288);
}

.List-container-checked {
    display: flex;
    justify-content: left;
    align-items: left;
    font-family: "roboto";
    height: 50px;
    width: 650px;
    background-color: #a89f9f;
    border-radius: 20px;
    box-shadow: 5px 5px 4px 0px rgba(0, 0, 0, 0.288);
    text-decoration: line-through;
}

.List-container-checked div {
    text-align: center;
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100%;
}

.List-container div {
    text-align: center;
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100%;
}

.task-checkinput {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.task-date {
    color: rgb(134, 133, 133);
    font-size: 0.7em;
    padding-right: 10px;
}

.task-delete {
    font-size: 1.3em;
    color: rgb(53, 76, 87);
}

.task-name {
    font-size: 1.1em
}

.task-text {
    min-width: 80vh;

    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
    font-family: "roboto";

    background-color: white;
    border-radius: 20px;
    padding: 10px 20px;
    /* box-shadow: 5px 5px 4px 0px rgba(0, 0, 0, 0.288); */

}

.task-text-checked {
    min-width: 80vh;

    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
    font-family: "roboto";

    background-color: #a89f9f;
    border-radius: 20px;
    padding: 10px 20px;
}

@media screen and (max-width: 720px) {



    .task-name {
        font-size: 0.7em;
    }

    .task-date {
        font-size: 0.7em;
    }





    .List-container-checked {

        padding: 10px 20px;
        gap: 0;
        height: auto;
        width: 98%;
    }

    .List-container {
        padding: 10px 20px;
        gap: 0;
        height: auto;
        width: 98%;
    }

    .List-container-checked {

        gap: 0;
        height: auto;
        width: 98%;
    }

    .List-container div {}

    .task-text {

        min-width: auto;
        gap: 20px;
        padding: 0 5px;
    }


    .task-text-checked {
        min-width: auto;
        gap: 20px;
        padding: 0 5px;
    }

    .task-delete {
        font-size: 1em;
    }
}