.categoryField {
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: rgba(58, 80, 100, 0.53);
    border: 2px solid #fff;
    border-radius: 25px;
    box-shadow: 0 1px 8px 2px rgba(0, 0, 0, .61);
    justify-content: space-evenly;
    padding: 2em;
    gap: 1em;

}

.categoryName {
    font-family: 'Roboto';
    color: white;
    font-size: 2.5em;
    font-weight: 700;
    padding: 0 .5em;
    text-align: center;
}

.admin-body {
    background-color: #dae4e6;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
}

.admin-title {
    text-align: center;
    margin-top: 50px;
    font-size: 2em;
}


.button-container {
    font-size: 2em;
    display: flex;
    justify-content: right;
    min-width: 100vh;
}

.new-cat {
    font-size: 30px;
}

.input-add-cat {
    height: 2.5em;
    font-family: 'Roboto';
}

button {
    padding: 10px 20px;
    font-size: 0.6em;
    border: none;
    border-radius: 15px;
    background: #bbc1bf;
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
}

.erreurCategory {
    background-color: #e38a8a;
    font-family: 'Roboto';
    font-size: 0.7em;
    padding: 8px;
    color: red;
    border-radius: 15px;
    margin-top: 20px;
}

@media (min-width: 720px) {}

@media screen and (max-width: 720px) {
    .admin-body {
        gap: 0;
        margin: 0;
        padding: 0;
    }

    .admin-title {
        margin-top: 20px
    }

    .h2 {
        margin: 0;
    }

    .categoryField {
        padding: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 98%;
    }

    .categoryName {
        font-size: 1.5em
    }

    .button-container {
        min-width: AUto;
    }

    .new-cat {
        margin: 20px;
    }

    .input-add-cat {
        margin-left: 30px;
    }


}