.login-container {
    height: 100vh;
    background-color: rgb(225, 245, 242);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    gap: 2em;
}

.login-container h2 {
    margin-top: 200px;
    font-size: 3em;
}

.login-container form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: left;
}


form input {
    width: 200px;
    border: none;
    background: none;
    outline: none;
    border-bottom: 2px solid lightgray;
    padding: 5px 3px;
    font-size: 1.4em;
}

form button {
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    border-radius: 15px;
    background: #bbc1bf;
}

.info-login {
    background-color: #ea1515;
    padding: 10px;
    border-radius: 10px;
}